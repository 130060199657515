import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { adminURL } from './config'

/* Estos son los Pages que se van a usar en toda la APP */

const Register = lazy( () => import('./pages/register/register') )
const Lobby = lazy( () => import('./pages/lobby/lobby') )
const Admin = lazy( () => import('./pages/admin/admin') )
const P404 = lazy( () => import('./pages/page404/page404') )

/*
  Hay varias versiones de React router DOM.
  La base de todos los eventos se hizo con una version anterior, El constructor tiene la nueva forma de usar el router
*/
function Routes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}> {/* Investigar que es Suspense y Lazy import */}
        <Switch>
          <Route exact path="/" component={Register}/>
          <Route exact path="/lobby" component={Lobby}/>
          <Route exact path={adminURL} component={Admin}/>
          <Route component={P404}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default Routes;
